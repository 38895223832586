<template>
  <v-container>
    Payment
    <BookCheckout />
  </v-container>
</template>

<script>
import BookCheckout from '@/components/BookCheckout'
export default {
  components: { BookCheckout }
}
</script>
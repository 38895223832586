<template>
  <v-container fluid style="background-color: #FFFFFF">
    <v-row class="d-flex justify-center ma-4">
      <v-col class="d-flex flex-column align-center">
        <h3 class="header-span header__text">Final step before your book goes to print!</h3>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center hard ml-4 mr-4">
      <v-col lg="4" md="4" cols="12" class="checkout__text">
        Hardcover upgrade for $15
      </v-col>
      <v-col lg="3" md="3" cols="12">
          <v-checkbox
              v-model="upgradeYes"
              @change="onUpgradeYes"
              color="white">
            <template v-slot:label>
              <span class="checkout__text">Yes, Please!</span>
            </template>
          </v-checkbox>
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            color="white"
            v-model="upgradeNo"
            @change="onUpgradeNo"
        >
          <template v-slot:label>
            <span class="checkout__text">No thanks</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="2" md="2" cols="12" class="checkout__text" style="text-align: center; border-left: 1px solid #fff;">
        {{ formatNumber(coverCharge) }}
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center pages ml-4 mr-4">
      <v-col lg="4" md="4" cols="12" class="checkout__text">
        Your {{ covertType }} book has {{ extraPages }} additional pages at {{ formatNumber(pagePrice) }} per page
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            v-model="additionalPages"
            color="white">
          <template v-slot:label>
            <span class="checkout__text">That's right!</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            color="white">
          <template v-slot:label>
            <span class="checkout__text" style="line-height: 40px;">
               Nope. Let's go back and fix that
            </span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="2" md="2" cols="12" class="checkout__text" style="text-align: center; border-left: 1px solid #fff;">
          {{ formatNumber(extraPagesPrice) }}
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center digital ml-4 mr-4">
      <v-col lg="4" md="4" cols="12" class="checkout__text">
        Add digital copy for $10
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            v-model="digitalYes"
            @change="onDigitalYes"
            color="white">
          <template v-slot:label>
            <span class="checkout__text">Yup!</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            @change="onDigitalNo"
            v-model="digitalNo"
            color="white">
          <template v-slot:label>
            <span class="checkout__text">
              Nah, I'm good
            </span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="2" md="2" cols="12" class="checkout__text" style="text-align: center; border-left: 1px solid #fff;">
        {{ formatNumber(digitalCharge) }}
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center shipping ml-4 mr-4">
      <v-col lg="4" md="4" cols="12" class="checkout__text">
        Expedited shipping $7.50
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            v-model="expShippingYes"
            @change="onShippingYes"
            color="white">
          <template v-slot:label>
            <span class="checkout__text">Yes!</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <v-checkbox
            @change="onShippingNo"
            v-model="expShippingNo"
            color="white">
          <template v-slot:label>
            <span class="checkout__text">
              No
            </span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col lg="2" md="2" cols="12" class="checkout__text" style="text-align: center; border-left: 1px solid #fff;">
        {{ formatNumber(shippingCharge) }}
      </v-col>
    </v-row>

    <AlertError class="ma-4 mt-10" :error="errorMsg" />
    <v-row class="d-flex justify-center ma-4">
      <v-col class="d-flex flex-column align-center">
        <v-btn
            class="btn mb-5 mb-lg-10"
            color="primary"
            block
            @click="onCheckout"
        >
          <span class="btn__text btn__text--white">Check Out</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center ma-4">
      <v-col class="d-flex flex-column align-center">
        <h3 class="header-span header__text">Questions? <a href="">See Book Options</a></h3>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import AlertError from '@/components/AlertError';

  export default {
    name: 'BookCheckout',
    components: {
      AlertError
    },
    props: {
      pageCount: {
        type: String,
        default: '0'
      }
    },
    data() {
      return {
        errorMsg: null,
        upgradeYes: null,
        upgradeNo: null,
        upgradeCover: false,
        additionalPages: null,
        digitalYes: false,
        digitalNo: false,
        expShippingYes: false,
        expShippingNo: false,
        expShipping: false,
        softcoverPagePrice: 75,
        hardcoverPagePrice: 125
      }
    },
    computed: {
      covertType() {
        return this.upgradeYes ? 'hardcover': 'softcover';
      },
      extraPages() {
        return this.pageCount - 31;
      },
      extraPagesPrice() {
        return this.additionalPages ? ((this.pageCount - 31) * this.pagePrice) : 0;
      },
      pagePrice() {
        return this.upgradeYes ? this.hardcoverPagePrice : this.softcoverPagePrice;
      },
      coverCharge() {
        return this.upgradeYes ? 1500 : 0;
      },
      digitalCharge() {
        return this.digitalYes ? 1000 : 0;
      },
      shippingCharge() {
        return this.expShippingYes ? 750 : 0;
      }
    },
    methods: {
      onCheckout() {
        let err = false
        this.errorMsg = null;
        if (this.upgradeNo === null && this.upgradeYes === null) {
          err = true
        }

        if (this.digitalYes === null && this.digitalNo === null) {
          err = true;
        }

        if (this.additionalPages === null) {
          err = true;
        }

        if (err) {
          this.errorMsg = 'Please answer all of the questions above.';
        } else {
          this.$emit('saveOptions', {
            hardCover: this.upgradeYes,
            digitalCopy: this.digitalYes,
            expShipping: this.expShippingYes,
            pages: this.extraPagesPrice
          });
        }

      },
      formatNumber(num) {
        return '$ ' + (num/100).toFixed(2);
      },
      onDigitalNo(evt) {
          this.digitalYes = !evt;
      },
      onDigitalYes(evt) {
        this.digitalNo = !evt;
      },
      onUpgradeYes(evt) {
        this.upgradeNo = !evt;
      },
      onUpgradeNo(evt) {
        this.upgradeYes = !evt;
      },
      onShippingYes(evt) {
        this.expShippingNo = !evt;
      },
      onShippingNo(evt) {
        this.expShippingYes = !evt;
      }
    }
  }
</script>

<style scoped>

.header__text {
  text-transform: uppercase;
}

.checkout__text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  color: #FFFFFF;
  /*line-height: 1em;*/
}

.hard {
  background-color: var(--v-primary-base);
}

.pages {
  background-color: var(--v-secondary-base);
}

.digital {
  background-color: var(--v-text-base);
}

.shipping {
  background-color: var(--v-secondaryBackgroundEmphasis-base);
}
</style>